import React from 'react'

const Footer = () => {
  return (
    <div className='flex justify-center py-6 border-t border-blue w-full bg-black text-white font-semibold'>
        2023 © Created by Rizky - All rights reserved.
    </div>
  )
}

export default Footer