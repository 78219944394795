import React from 'react'

const Blog = () => {
  return (
    <div className='h-screen bg-black text-blue flex justify-center items-center text-xl font-bold'>
        Coming Soon
    </div>
  )
}

export default Blog